import * as React from "react";
import { CircularProgress, Box } from "@mui/material";
import { executePayment } from "react-ordrio-swipe";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Loading = () => {
  const navigate = useNavigate();
  const { sessionId, mode } = useParams();

  useEffect(() => {
    if (
      sessionId !== "undefined" &&
      (mode === "sandbox" || mode === "production")
    ) {
      executePayment(sessionId, mode);
    } else {
      navigate("/invalid");
    }
  }, [sessionId, mode, navigate]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default Loading;
