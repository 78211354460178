import React, { useEffect } from "react";
import "./lib/index.css";

const pageStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100vw",
  height: "100vh",
};

const Invalid = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []); // Empty dependency array

  return (
    <div style={pageStyle}>
      <div className="text-wrapper">
        <div className="title" data-content="404">
          ACCESS DENIED
        </div>

        <div className="subtitle">Invalid mode or sessionId </div>
      </div>
    </div>
  );
};

export default Invalid;
