import { useEffect } from "react";
import { PageNotFound404 } from "./lib/index";

const pageStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100vw",
  height: "100vh",
};

const PageNotFound = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []); // Empty dependency array

  return (
    <div style={pageStyle}>
      <PageNotFound404 style={{ fontSize: "20em" }} />
    </div>
  );
};

export default PageNotFound;
