import "./index.css";

export const PageNotFound404 = (props) => (
  <div className="text-wrapper">
    <div className="title" data-content="404">
      403
    </div>

    <div className="subtitle">ACCESS DENIED</div>
  </div>
);
