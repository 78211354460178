import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PageNotFound from "./PageNotFound";
import Loading from "./Loading";
import Invalid from "./Invalid";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/checkout/:sessionId/:mode" element={<Loading />} />
        <Route path="/invalid" element={<Invalid />} />
        <Route path="*" element={<PageNotFound />} />{" "}
      </Routes>
    </Router>
  );
}

export default App;
